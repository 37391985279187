import { DecimalPipe } from "@angular/common";
import {
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { TableDataType } from "src/app/opportunity-details/license-calculator/license-calculator.type";

@Directive({
  selector: "[licenseCalculation]",
  exportAs: "licenseCalculation",
})
export class LicenseCalculationDirective implements OnChanges {
  @Input() baseValue: number;
  @Input() licenseData: TableDataType;
  @Input() hideData: boolean;
  @Input() isViewingBundleLicensing: any;
  @Input() totalCostFlag: boolean;
  @Input() totalCostFlagDmaas: boolean;
  @Input() totalDmaasReplicationFlag: boolean;
  @Input() totalGaiaFlag: boolean;

  isHidden: boolean = false;
  isExpanded: boolean = false;
  isFloatRight: boolean = false;
  giaTexts: string = "";

  constructor(private decimalPipe: DecimalPipe) {}

  ngOnChanges(changes: SimpleChanges) {
    this.calculate();
  }

  private calculate() {
    const {
      isGaiaBundle,
      isRetail,
      isTotalCostFlag,
      isTotalCostFlagDmaas,
      isTotalDmaasReplicationFlag,
      isExpandable,
    } = this.licenseData;

    this.isHidden = this.checkForHide();
    if (isExpandable) {
      this.isExpanded = this.checkForExpand();
    }
    if (isGaiaBundle) {
      this.giaTexts = this.formatGaiaTexts();
    }
    this.isFloatRight =
      !isExpandable &&
      (isGaiaBundle ||
        isRetail ||
        isTotalCostFlag ||
        isTotalCostFlagDmaas ||
        isTotalDmaasReplicationFlag);
  }

  private formatGaiaTexts(): string {
    const { skuName, value } = this.licenseData;
    const { y1, cost1, gaia_gen_answers_gpt3_5, gaia_gen_answers_gpt4 } = value;
    const T = (num: number) => this.decimalPipe.transform(num, "1.");
    const years = T(y1);
    const cost = T(cost1);
    switch (skuName) {
      case "":
        return `(${years}GB Index Storage<br>+${T(gaia_gen_answers_gpt3_5 + gaia_gen_answers_gpt4)}Generative Answers)/<br>$${cost}`;
      case "SAAS-GAIA-GPT3.5T-16K-AWS":
        return `${years}(${T(y1 * 100)}GB Index Storage<br>+${T(y1*10000)}Generative Answers)/<br>$${cost}`;
      case "SAAS-GAIA-GPT-4-8K-AWS":
        return `${years}(${T(y1 * 100)}GB Index Storage<br>+${T(y1*5000)}Generative Answers)/<br>$${cost}`;
      case "SAAS-GAIA-INDEX-AWS":
        return `${years}(${T(y1)}GB Index Storage)/<br>$${cost}`;
      case "SAAS-GAIA-LLM-GPT3.5T-16K":
        return `${years}(${T(y1 * 1000)} Generative Answers)/<br>$${cost}`;
      case "SAAS-GAIA-LLM-GPT4-8K":
        return `${years}(${T(y1 * 1000)} Generative Answers)/<br>$${cost}`;
    }
    return "";
  }

  private checkForHide(): boolean {
    const baseCondition = this.hideData || this.isViewingBundleLicensing;
    const {
      isRetail,
      isTotalCostFlag,
      isTotalCostFlagDmaas,
      isTotalDmaasReplicationFlag,
      isExpandable,
      isGaiaBundle,
      isShowOnBundleLicensing,
    } = this.licenseData;

    if (isExpandable) {
      return baseCondition;
    }
    if (isRetail) {
      if (isShowOnBundleLicensing) {
        return this.isViewingBundleLicensing;
      }
      return !this.isViewingBundleLicensing;
    }
    if (isTotalCostFlag) {
      return baseCondition || !this.totalCostFlag;
    }
    if (isTotalCostFlagDmaas) {
      return baseCondition || !this.totalCostFlagDmaas;
    }
    if (isTotalDmaasReplicationFlag) {
      return baseCondition || !this.totalDmaasReplicationFlag;
    }
    if (isGaiaBundle) {
      return baseCondition || !this.totalGaiaFlag;
    }

    return baseCondition;
  }

  private checkForExpand(): boolean {
    const {
      isGaiaBundle,
      isTotalCostFlag,
      isTotalCostFlagDmaas,
      isTotalDmaasReplicationFlag,
    } = this.licenseData;

    if (isTotalCostFlag) {
      return this.totalCostFlag;
    }
    if (isTotalCostFlagDmaas) {
      return this.totalCostFlagDmaas;
    }
    if (isTotalDmaasReplicationFlag) {
      return this.totalDmaasReplicationFlag;
    }
    if (isGaiaBundle) {
      return this.totalGaiaFlag;
    }

    return false;
  }
}
